import React, { useEffect, useState } from "react";
import { Table, Button, Input } from "antd";
import axios from "axios";
import BackButton from "../components/BackButton";
import { AiFillDelete } from "react-icons/ai";

const AccountDetails = () => {
const base_url=process.env.REACT_APP_BASE_URL 

  const [accountDetails, setAccountDetails] = useState([]);
  const [newAccount, setNewAccount] = useState({
    accountNumber: "",
    ifscCode: "",
    upiId: "",
  });

  // Fetch the account details on component mount
  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        const res = await axios.get(`${base_url}admin/get-account`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setAccountDetails(res.data);
      } catch (error) {
        console.error("Error fetching account details:", error);
      }
    };
    fetchAccountDetails();
  }, []);

  // Add a new account
  const handleAddAccount = async () => {
    try {
      const res = await axios.post(`${base_url}admin/add-account`, newAccount, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setAccountDetails(res.data);
      setNewAccount({ accountNumber: "", ifscCode: "", upiId: "" });
    } catch (error) {
      console.error("Error adding account:", error);
    }
  };

  // Delete an account
  const handleDeleteAccount = async (accountId) => {
    try {
      await axios.delete(`${base_url}admin/delete-account/${accountId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setAccountDetails(
        accountDetails.filter((account) => account._id !== accountId)
      );
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  // Define columns for Ant Design table
  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    {
      title: "IFSC Code",
      dataIndex: "ifscCode",
      key: "ifscCode",
    },
    {
      title: "UPI ID",
      dataIndex: "upiId",
      key: "upiId",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button danger onClick={() => handleDeleteAccount(record._id)}>
          <AiFillDelete />
        </Button>
      ),
    },
  ];

  // Transform accountDetails data into a format suitable for the table
  const data = accountDetails.map((account, index) => ({
    key: index + 1,
    accountNumber: account.accountNumber,
    ifscCode: account.ifscCode,
    upiId: account.upiId,
    _id: account._id, // for deletion
  }));

  return (
    <div className="account-details">
      <h2>
        <BackButton /> Account Details
      </h2>

      {/* Form to add new account */}
      <div style={{ marginBottom: "20px" }}>
        <Input
          type="number"
          style={{ width: "200px", marginRight: "10px" }}
          placeholder="Account Number"
          value={newAccount.accountNumber}
          onChange={(e) =>
            setNewAccount({ ...newAccount, accountNumber: e.target.value })
          }
        />
        <Input
          style={{ width: "150px", marginRight: "10px" }}
          placeholder="IFSC Code"
          value={newAccount.ifscCode}
          onChange={(e) =>
            setNewAccount({ ...newAccount, ifscCode: e.target.value })
          }
        />
        <Input
          style={{ width: "150px", marginRight: "10px" }}
          placeholder="UPI ID"
          value={newAccount.upiId}
          onChange={(e) =>
            setNewAccount({ ...newAccount, upiId: e.target.value })
          }
        />
        <Button type="primary" onClick={handleAddAccount}>
          Add Account
        </Button>
      </div>

      {/* Table to display account details */}
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }} // Limit rows per page
        scroll={{ x: "100vw" }} // Allow horizontal scrolling on smaller screens
      />
    </div>
  );
};

export default AccountDetails;
