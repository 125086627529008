import { React, useState } from "react";
import { createProducts } from "../features/product/productSlice";
import { useDispatch } from "react-redux";
import BackButton from "../components/BackButton";

const Addproduct = () => {
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null); // Change to null for file
  const [color, setColor] = useState("");
  const [description, setDescription] = useState("");
  const [preview, setPreview] = useState("");

  const dispatch = useDispatch();

  const handleFileUpload1 = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImage(file); // Save the file directly
      const imageUrl = URL.createObjectURL(file); // Create a temporary URL for the file
      setPreview(imageUrl); // Set the preview to the temporary URL
    } else {
      setImage(null);
      setPreview("");
    }
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();

    const productData = new FormData();
    productData.append("category", category);
    productData.append("description", description);
    productData.append("brand", brand);
    productData.append("price", price);
    productData.append("color", color); // Convert to array if necessary
    productData.append("image", image); // Append the file directly

    dispatch(createProducts(productData)); // Dispatch the FormData
  };

  return (
    <div>
      <h3 className="mb-4 title">
        <BackButton /> Add Product
      </h3>
      <div>
        <form onSubmit={handleSubmit1} className="d-flex gap-3 flex-column">
          <div>
            <label>Category:</label>
            <input
              type="text"
              placeholder="provide category like men,women..."
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Description:</label>
            <input
              type="text"
              placeholder="Provide only one category like tshirt"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Price:</label>
            <input
              type="number"
              placeholder="Price for this category"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Brand:</label>
            <input
              type="text"
              placeholder="Brand"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Color:</label>
            <input
              type="text"
              placeholder="color of the fabric"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Image:</label>
            <input
              type="file"
              accept="image/*"
              placeholder="provide fabric image"
              onChange={handleFileUpload1}
              required
            />
          </div>
          {preview && (
            <img
              src={preview}
              alt="Preview"
              style={{ width: "200px", height: "auto" }}
            />
          )}

          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
          >
            Add Product
          </button>
        </form>
      </div>
    </div>
  );
};

export default Addproduct;
