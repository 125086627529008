import React, { useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { FaFirstOrderAlt } from "react-icons/fa";
import { LiaSignOutAltSolid, LiaAddressBook } from "react-icons/lia";
import { BiSupport } from "react-icons/bi";
import { RiFeedbackFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../features/auth/authSlice";
import { getToken, getUsername } from "../utils/axiosconfig";
import { MdOutlinePlaylistAddCheckCircle } from "react-icons/md";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await dispatch(logout()).unwrap();
      navigate("/"); // Navigate to the login page on successful logout
      window.location.reload();
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const token = getToken();
  const username = getUsername();

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  const handleItemClick = () => setSidebarOpen(false); // Close the sidebar when an item is clicked

  return (
    <>
      <header className="header">
        <div className="left-section">
          <div className="menu-icon" onClick={toggleSidebar}>
            &#9776;
          </div>{" "}
          {/* Menu Icon */}
        </div>
        <div className="center-section " style={{ fontSize: "15px" }}>
          <Link to="/admin">
            {" "}
            <span className="logo fav text-black">FLEXURA.AI</span>
          </Link>{" "}
          {/* Logo */}
        </div>
        <div className="right-section">
          {token ? (
            <span> {username}!</span>
          ) : (
            <Link to="/">
              <span className="icon user-icon m-4 ">🔑 Login</span>
            </Link>
          )}
        </div>
        {/* Sidebar */}
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <div className="close-icon" onClick={toggleSidebar}>
            ×
          </div>
          <div className="login-section">
            <span className="login-icon">🙂</span>
            {token ? (
              <span>{username}!</span>
            ) : (
              <span className="login-text">LOG IN</span>
            )}
          </div>
          <ul className="menu-list">
            <Link to="/admin/product" onClick={handleItemClick}>
              {" "}
              <li>
                <AiOutlineShoppingCart /> ADD-PRODUCTS
              </li>
            </Link>
            <Link to="/admin/list-product" onClick={handleItemClick}>
              {" "}
              <li>
                <MdOutlinePlaylistAddCheckCircle /> PRODUCT-LIST
              </li>
            </Link>
            <Link to="/admin/orders" onClick={handleItemClick}>
              {" "}
              <li>
                <FaFirstOrderAlt /> ORDERS
              </li>
            </Link>
            <Link to="/admin/accountdetails" onClick={handleItemClick}>
              {" "}
              <li>
                <MdOutlinePlaylistAddCheckCircle /> ACCOUNT-DETAILS
              </li>
            </Link>

            <Link to="/admin/feedback" onClick={handleItemClick}>
              <li>
                <RiFeedbackFill /> FEEDBACK
              </li>
            </Link>

            <Link to="/admin/footer" onClick={handleItemClick}>
              <li>
                <BiSupport /> CONTACT
              </li>
            </Link>
            <li onClick={handleLogout}>
              <LiaSignOutAltSolid /> SIGNOUT
            </li>
          </ul>
        </div>
      </header>
      {/* <div>  <Outlet /></div> */}
      {/* <Dashboard /> */}
    </>
  );
};
export default Header;
