import React from "react";
const Footer = () => {
  return (
    <>
      <footer className="py-4">
        <div className="container-xxl">
          <div className="row">
            <div className="col-4">
              <h4 className="fav mb-4">Contact Us</h4>
              <div>
                
                <a
                  href="mailto:raunakjaiswal711@gmail.com"
                  className="fav mt-2 d-block mb-0 "
                >
                  raunakjaiswal711@gmail.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
