import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Icons for eye toggle

const ResetPassword = () => {
const base_url=process.env.REACT_APP_BASE_URL 

  const { token } = useParams(); // Retrieve the token from the URL
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility
  const [isTokenValid, setIsTokenValid] = useState(null); // New state to track token validity

  // Check if the token is valid when the component mounts
  useEffect(() => {
    const verifyToken = async () => {
      try {
        // Make an API call to verify the token
        const response = await axios.get(
          `${base_url}admin/verify-reset-token/${token}`
        );
        setIsTokenValid(true); // If token is valid
      } catch (error) {
        setIsTokenValid(false); // If token is invalid or expired
        setError(
          "The reset password link has expired. Please request a new one."
        );
      }
    };

    verifyToken();
  }, [token]);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      // Make a request to the backend to reset the password
      const response = await axios.put(
        `${base_url}admin/reset-password/${token}`,
        { password }
      );
      setSuccess(response.data.message);
      toast.success("Your Password Has Been Updated");

      setTimeout(() => {
        navigate("/"); // Redirect to login after successful reset
      }, 2000);
    } catch (error) {
      setError(error.response?.data?.message || "An error occurred");
    }
  };

  // Show loading indicator while token is being verified
  if (isTokenValid === null) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className="section"
        style={{
          border: "1px solid #ddd",
          background: "rgb(212, 233, 226,0.6)",
        }}
      >
        <h1>FLEXURA.AI</h1>
      </div>
      <div className="auth-container">
        {isTokenValid ? (
          <form onSubmit={handleSubmit} className="auth-form">
            <h2>Reset-Password</h2>
            {error && <div className="error-message text-center">{error}</div>}

            <div className="input-group">
              <label htmlFor="password">New Password</label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span onClick={togglePassword} className="eye-icon">
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            <div className="input-group">
              <label htmlFor="confirmPassword">Confirm New Password</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            <button type="submit" className="submit-button">
              Reset-Password
            </button>
          </form>
        ) : (
          <div className="error-message">
            <h2>{error}</h2>
            <button
              onClick={() => navigate("/forgot-password")}
              className="submit-button"
            >
              Request New Reset Link
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ResetPassword;
