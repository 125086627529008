import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { registerAdmin } from "../features/auth/authSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Icons for eye toggle
import { useNavigate } from "react-router-dom";

const signUpSchema = yup.object({
  firstname: yup.string().required("First Name is Required "),
  lastname: yup.string().required("Last Name is Required "),
  email: yup
    .string()
    .email("Email Should be Valid")
    .required("Email Address is Required"),
  mobile: yup.string().required("Mobile No is Required "),
  password: yup.string().required("Password is Required "),
});

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      password: "",
    },
    validationSchema: signUpSchema,
    onSubmit: (values) => {
      dispatch(registerAdmin(values))
        .unwrap() // Unwraps the Promise and returns the result of fulfilled/rejected
        .then(() => {
          navigate("/"); // Navigate to home page if Signup is successful
        })
        .catch((err) => {
          setErrorMessage(err.message || "Invalid credentials");

          // toast.error(err.message || "Invalid Credentials") // Show error toast
        });
    },
  });

  return (
    <>
      <div
        className="section"
        style={{
          border: "1px solid #ddd",
          background: "rgb(212, 233, 226,0.6)",
        }}
      >
        <h1 className="fav"> FLEXURA.AI</h1>
      </div>
      <div className="auth-container">
        <form onSubmit={formik.handleSubmit} className="auth-form">
          <h2>Signup</h2>
          {errorMessage && (
            <div className="error-message text-center">{errorMessage}</div>
          )}
          <div className="input-group">
            <label>Firstname</label>
            <input
              type="text"
              name="firstname"
              value={formik.values.firstname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter your firstname"
            />
            {formik.touched.firstname && formik.errors.firstname ? (
              <div className="error-message">{formik.errors.firstname}</div>
            ) : null}
          </div>
          <div className="input-group">
            <label>Lastname</label>
            <input
              type="text"
              name="lastname"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter your lastname"
            />
            {formik.touched.lastname && formik.errors.lastname ? (
              <div className="error-message">{formik.errors.lastname}</div>
            ) : null}
          </div>
          <div className="input-group">
            <label>Mobile</label>
            <input
              type="number"
              name="mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter your mobile"
            />
            {formik.touched.mobile && formik.errors.mobile ? (
              <div className="error-message">{formik.errors.mobile}</div>
            ) : null}
          </div>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter your email"
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error-message">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="input-group">
            <label>Password</label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter your password"
            />
            <span onClick={togglePassword} className="eye-icon">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
            {formik.touched.password && formik.errors.password ? (
              <div className="error-message">{formik.errors.password}</div>
            ) : null}
          </div>

          <button type="submit" className="submit-button">
            Signup
          </button>
        </form>
      </div>
    </>
  );
};

export default Signup;
