import "./App.css";
import {  Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Signup from './pages/Signup'
import Resetpassword from "./pages/Resetpassword";
import Forgotpassword from "./pages/Forgotpassword";
import MainLayout from "./components/MainLayout";
import Orders from "./pages/Orders";
import Productlist from "./pages/Productlist";
import Addproduct from "./pages/Addproduct";
import ViewOrder from "./pages/ViewOrder";
import { PrivateRoutes } from "./routing/PrivateRoute";
import AccountDetails from "./pages/AccountDetails";
import FeedbackPage from "./pages/FeedbackPage";
import useAxiosInterceptor from "./utils/axiosInstance";
import Footer from "./components/Footer";
function App() {

  useAxiosInterceptor(); // Activate the Axios interceptor

  return (
    
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password/:token" element={<Resetpassword />} />
        <Route path="/forgot-password" element={<Forgotpassword />} />

        <Route path="/admin" element={<PrivateRoutes><MainLayout /></PrivateRoutes>}>
          <Route index element={<Orders />} />

          <Route path="orders" element={<Orders />} />
          <Route path="order/:id" element={<ViewOrder />} />
          <Route path="accountdetails" element={<AccountDetails />} />

          <Route path="feedback" element={<FeedbackPage />} />
          <Route path="list-product" element={<Productlist />} />
          <Route path="product" element={<Addproduct />} />
          <Route path="footer" element={<Footer />} />
        </Route>
      </Routes>
    
  );
}

export default App;
