// FeedbackPage.js
import React, { useState, useEffect } from 'react';
import FeedbackModal from '../components/Feedback'; // Import the modal component

const FeedbackPage = () => {
  const [showModal, setShowModal] = useState(false); // Modal visibility

  // Automatically show modal when the page loads
  useEffect(() => {
    setShowModal(true);
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="feedback-page">
      

      {/* Feedback modal */}
      {showModal && <FeedbackModal showModal={showModal} closeModal={closeModal} />}
    </div>
  );
};

export default FeedbackPage;
