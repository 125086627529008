import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const Forgotpassword = () => {
const base_url=process.env.REACT_APP_BASE_URL 

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    try {
      const response = await axios.post(
        `${base_url}admin/forgot-password-token`,
        { email }
      );
      setMessage(response.data);

      setError("");
      toast.info("The Link Has Been Sent On Your Email");
    } catch (err) {
      setError("Error sending reset email");
      setMessage("");
    }
  };
  return (
    <>
      <div
        className="section"
        style={{
          border: "1px solid #ddd",
          background: "rgb(212, 233, 226,0.6)",
        }}
      >
        <h1 className="fav">FLEXURA.AI</h1>
      </div>
      <div className="auth-container">
        <form onSubmit={handleSubmit} className="auth-form">
          <h2>Forgot Password</h2>

          <p className="text-center">
            Please Enter your register email to get reset password mail.
          </p>

          {/* Display error message if error exists */}
          {error && (
            <div className="error-message text-center" style={{ color: "red" }}>
              {error}
            </div>
          )}

          {/* Display success message if success exists */}
          {message && (
            <div
              className="success-message text-center"
              style={{ color: "green" }}
            >
              {message}
            </div>
          )}

          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <button type="submit" className="submit-button">
            Reset
          </button>
          <div>
            <Link to="/" className="text-black">
              Cancel
            </Link>
          </div>
          <div></div>
        </form>
      </div>
    </>
  );
};

export default Forgotpassword;
