// FeedbackModal.js
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';

const Feedback = ({ showModal, closeModal }) => {
const base_url=process.env.REACT_APP_BASE_URL 

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [mobile, setMobile] = useState('');
  const navigate=useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${base_url}enquiry`, {
        name,
        email,
        feedback,
        mobile
      });

      toast.success('Thank you for your feedback!');
      navigate("/admin")
      setName('');
      setEmail('');
      setFeedback('');
      setMobile('');
      closeModal();
      
    } catch (error) {
      toast.error('Error submitting feedback');
    }
  };

  if (!showModal) return null; // Do not render the modal if showModal is false

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Feedback Form</h2>
        <p>We value your feedback. Please leave your thoughts and suggestions below.</p>

        <form onSubmit={handleSubmit} className="feedback-form">
          <div className="input-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="mobile">Mobile</label>
            <input
              type="number"
              id="mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="feedback">Your Feedback</label>
            <textarea
              id="feedback"
              value={feedback}
              rows="3"
              style={{ height: "auto",resize:"none" }}
              onChange={(e) => setFeedback(e.target.value)}
              required
            />
          </div>
          <button className='submit-feedback' type="submit">Submit Feedback</button>
        </form>
        <Link to='/admin'><button className="modal-close" onClick={closeModal}>
        &times;
        </button></Link>
      </div>
    </div>
  );
};

export default Feedback;
