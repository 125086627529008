import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { login } from "../features/auth/authSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Icons for eye toggle

let schema = yup.object().shape({
  email: yup
    .string()
    .email("Email should be valid")
    .required("Email is Required"),
  password: yup.string().required("Password is Required"),
});
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(login(values))
        .unwrap() // Unwraps the Promise and returns the result of fulfilled/rejected
        .then(() => {
          navigate("/admin"); // Navigate to home page if login is successful
        })
        .catch((err) => {
          setErrorMessage(err.message || "Invalid credentials");
        });
    },
  });

  return (
    <>
      <div>
        <div
          className="section"
          style={{
            border: "1px solid #ddd",
            background: "rgb(212, 233, 226,0.6)",
          }}
        >
          <h1 className="fav">  FLEXURA.AI</h1>
        </div>
        <div className="auth-container ">
          <form onSubmit={formik.handleSubmit} className="auth-form">
            <h2>Login</h2>
            {errorMessage && (
              <div className="error-message text-center">{errorMessage}</div>
            )}

            <div className="input-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter your email"
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error-message">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="input-group">
              <label>Password</label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter your password"
              />
              <span onClick={togglePassword} className="eye-icon">
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
              {formik.touched.password && formik.errors.password ? (
                <div className="error-message">{formik.errors.password}</div>
              ) : null}
            </div>

            <button type="submit" className="submit-button">
              Login
            </button>
            <div>
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
            <div>
              <Link to="/signup">
                <p className="text-black">Create an account</p>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
