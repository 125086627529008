import React, { useEffect, useState } from "react";
import { Table, Select } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import BackButton from "../components/BackButton";
const { Option } = Select;

// Ant Design Table Columns MAIN
const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "OrderId",
    dataIndex: "orderid",
  },
  {
    title: "Product",
    dataIndex: "product",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
];

const Orders = () => {
const base_url=process.env.REACT_APP_BASE_URL 

  const [orders, setOrders] = useState([]);

  const token = localStorage.getItem("token"); // Fetch token from localStorage

  // Fetch admin orders using Axios
  useEffect(() => {
    const fetchAdminOrders = async () => {
      try {
        const response = await axios.get(`${base_url}admin/get-orders`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching admin orders:", error);
      }
    };

    fetchAdminOrders();
  }, []);

  // Handle order status change
  const handleStatusChange = async (value, orderId) => {
    try {
      await axios.put(
        `${base_url}admin/order/update-order/${orderId}`,
        { status: value },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the order status in the local state
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === orderId ? { ...order, status: value } : order
        )
      );
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };
  // Prepare data for Ant Design Table
  const data1 = orders.map((order, index) => ({
    key: index + 1,
    orderid: order._id, // Assuming name as shipping address here
    product: (
      <Link to={`/admin/order/${order._id}`} className="view-order-link">
        View Orders
      </Link>
    ),
    amount: order.totalPrice,
    date: new Date(order.createdAt).toLocaleString(),

    status: (
      <Select
        defaultValue={order.status}
        onChange={(value) => handleStatusChange(value, order._id)}
        style={{ width: 150 }}
      >
        <Option value="Order Confirmed">Order Confirmed</Option>
        <Option value="Shipped">Shipped</Option>
        <Option value="Out for Delivery">Out for Delivery</Option>
        <Option value="Delivered">Delivered</Option>
        <Option value="Cancelled">Cancelled</Option>
      </Select>
    ),
  }));

  return (
    <div className="admin-orders-container">
      <h3 className="mb-4 title">
        <BackButton /> Orders for Your Products
      </h3>

      <Table
        columns={columns}
        dataSource={data1}
        scroll={{ x: "100vw" }} // Allow horizontal scrolling on smaller screens
      />
    </div>
  );
};
export default Orders;
