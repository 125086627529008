import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import productService from "./productService";
import { toast } from "react-toastify";

// Async Thunk for creating a new product
export const createProducts = createAsyncThunk(
  "product/create",
  async (productData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      return await productService.createProducts(productData, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getProducts = createAsyncThunk(
  "product/getproducts",
  async ({ thunkAPI }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await productService.getProducts(token);
      return response;
    } catch (error) {
      // Handle the case where error.response might be undefined
      const errorMessage =
        error.response?.data?.message || error.message || "An error occurred";

      return thunkAPI.rejectWithValue(error.response.data, errorMessage);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/delete",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await productService.deleteProduct(id, token);
      return id; // Return the deleted product ID
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const resetState = createAction("Reset_all");

const initialState = {
  products: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    // Reducer to remove product from state
    removeProduct(state, action) {
      state.products = state.products.filter(
        (product) => product._id !== action.payload
      );
    },
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    reset: (state) => {
      state.products = [];
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.products = action.payload;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createProducts.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(createProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.products.push(action.payload); // Add the newly created product to the list
        if (state.isSuccess === true) {
          toast.info("Product Created Successfully");
        }
      })
      .addCase(createProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.products = state.products.filter(
          (product) => product._id !== action.payload
        );
        if (state.isSuccess === true) {
          toast.info("Product Deleted Successfully");
        }
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isError = action.payload;
      })
      // Reset State
      .addCase(resetState, () => initialState);
  },
});
export const { resetSuccess } = productSlice.actions;
export const { reset } = productSlice.actions;

export const { removeProduct } = productSlice.actions;

export default productSlice.reducer;
